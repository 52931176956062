import axiosInstance from '@/plugins/axios';

export default class ServiceAdmin {
  static endpoint = 'admin';

  static digitalCertificate = {
    get: () => {
      return axiosInstance.get(`${this.endpoint}/certificate`);
    },
    getByRoot: (root) => {
      return axiosInstance.get(`${this.endpoint}/company-config/${root}`);
    },
    associate: (data) => {
      return axiosInstance.post(`${this.endpoint}/company-config`, data);
    },
    historic: (id) => {
      return axiosInstance.get(`${this.endpoint}/company-config/certificate-history/${id}`);
    },
  };

  static company = {
    get: (params) => {
      return axiosInstance.get(`${this.endpoint}/company/search?${params}`);
    },
    getWithConfigs: (params) => {
      return axiosInstance.get(`${this.endpoint}/company/configs?${params}`);
    },
    saveConfigs: (companyId, payload) => {
      return axiosInstance.post(`${this.endpoint}/company/${companyId}/configs`, payload);
    },
    robotsHistory: (rootCnpj) => {
      return axiosInstance.get(`${this.endpoint}/company-config/robots/history/${rootCnpj}`);
    },
    metabaseHistorico: (rootCnpj) => {
      return axiosInstance.get(`${this.endpoint}/company-config/metabase/history/${rootCnpj}`);
    },
    getMetabaseUrl: (cnpj) => {
      return axiosInstance.get(`${this.endpoint}/company-config/metabase/url?rootCnpj=${cnpj}`);
    },
  };

  static group = {
    get: () => {
      return axiosInstance.get(`${this.endpoint}/group/search`);
    },
    save: (grupo) => {
      return axiosInstance.post(`${this.endpoint}/group`, grupo);
    },
  };

  static employer = {
    getAll: (pagination) => {
      return axiosInstance.get(`${this.endpoint}/companyestab/?${pagination}`);
    },
  };

  static establishment = {
    get: (params) => {
      return axiosInstance.get(`${this.endpoint}/establishment/search?${params}`);
    },
    sendMessageToQueue: (cnpj) => {
      return axiosInstance.get(`${this.endpoint}/establishment/sendmessagetoqueue?&cnpjEmpresaMatriz=${cnpj}`);
    },
  };

  static userEstablishment = {
    get: (id, onlyMatriz = false) => {
      return axiosInstance.get(
        `${this.endpoint}/user-establishment/findbyuserid/${id}${onlyMatriz ? '?onlyMatriz=true' : ''}`,
      );
    },
    save: (data) => {
      return axiosInstance.post(`${this.endpoint}/user-establishment`, data);
    },
    saveAll: (data, id) => {
      return axiosInstance.post(`${this.endpoint}/user-establishment/saveAll/${id}`, data);
    },
    delete: (id) => {
      return axiosInstance.delete(`${this.endpoint}/user-establishment/${id}`);
    },
  };

  static wizard = {
    get: (data) => {
      return axiosInstance.get(`${this.endpoint}/company-details/save-cascade/${data.cnpj}/${data.id}`);
    },
  };

  static usability = {
    downloadUsabilityReport: (data) => {
      return axiosInstance.post(`${this.endpoint}/usability`, data, {
        responseType: 'arraybuffer',
        contentType: 'application/force-download',
        'Content-Disposition': 'attachment',
      });
    },
    findParams: (fromTime, toTime) => {
      return axiosInstance.get(`${this.endpoint}/usability/filters/${fromTime}/${toTime}`);
    },
  };
}
