import MENU_ROLES from '@/constants/ConstantMenuRoles';
import TIPO_NAVEGACAO_PERFIL from '@/constants/ConstantTipoNavegacaoPerfil';
import ServiceAdmin from '@/services/ServiceAdmin';
import ServiceAuth from '@/services/ServiceAuth';
import ServiceCertificate from '@/services/ServiceCertificate';
import ServiceFinancial from '@/services/ServiceFinancial';

import UtilsMask from '@/utils/UtilsMask';
import { cloneDeep, groupBy, sortBy, uniqueId } from 'lodash';
import { defineStore } from 'pinia';

export const useStoreAuth = defineStore('auth', {
  state: () => ({
    validPassword: false,
    showModal: false,
    token: localStorage.getItem('access_Token'),
    refreshToken: '',
    error: '',
    credentials: {},
    authorities: [],
    userValidated: false,
    requireToken: false,
    validationUserErrors: '',
    moduleMessage: '',
    thumbnail: null,
    user: {},
    pendency: false,
    totalizers: false,
    adminMenus: [],
    userEstablishments: [],
    tipoNavegacaoPerfil: null,
    loadingUserEstablishments: true,
  }),

  getters: {
    hasMenuSettings(state) {
      return state.authorities.includes('ROLE_ADMIN');
    },
    hasMenuHelp(state) {
      return state.authorities.includes('ROLE_ADMIN');
    },
    getUserMail(state) {
      return state.user?.emailAddress;
    },
    getTfaEnabled(state) {
      return state.user?.twoFactorAuthenticationEnabled;
    },
    isAdmin(state) {
      return state.authorities.includes('ROLE_ADMIN');
    },
    userRootCnpjs(state) {
      return state.userEstablishments.filter((item) => !item.details?.matriz);
    },
    parsedRootCnpjs(state) {
      return parseListWithoutGroups(state.userRootCnpjs);
    },
    parsedEstablishments(state) {
      const establishmentsGroupedBySocialName = getEstablishmentsGroupedBySocialName(state.userEstablishments);
      const listGroupItems = parseListGroupItems(establishmentsGroupedBySocialName);

      return separateAndFlattenGroupItems(listGroupItems);
    },
    isPerfilAnalista(state) {
      return state.tipoNavegacaoPerfil === TIPO_NAVEGACAO_PERFIL.ANALISTA;
    },
  },

  actions: {
    async getUser() {
      try {
        const { data } = await ServiceAuth.user.getUser();

        if (data) {
          const user = {
            id: data.id,
            firstName: data.firstName,
            lastName: data.lastName,
            username: data.username,
            cellPhone: data.cellPhone,
            emailAddress: data.emailAddress,
            authorities: data.authorities,
            thumbnailId: data.thumbnailId,
            twoFactorAuthenticationEnabled: data.twoFactorAuthenticationEnabled,
          };

          this.authorities = user.authorities;
          this.user = user;

          this.tipoNavegacaoPerfil = this.authorities.some((i) => MENU_ROLES.analistaAgnes.includes(i))
            ? TIPO_NAVEGACAO_PERFIL.ANALISTA
            : TIPO_NAVEGACAO_PERFIL.USUARIO;

          return user;
        }
      } catch (e) {
        this.errorSubmit(e);
      }
    },

    setMessage(message) {
      this.moduleMessage = message;
    },

    async saveUser(payload) {
      payload.thumbnailId = this.user.thumbnailId;
      payload.twoFactorAuthenticationEnabled = this.user.twoFactorAuthenticationEnabled;
      const user = cloneDeep(payload);
      this.user = payload;
      delete user.authorities;
      delete user.username;
      delete user.id;

      await ServiceAuth.user.update(user);
    },
    changeModal(payload) {
      this.showModal = payload;
    },
    async signIn(credentials) {
      this.removeToken();
      this.loading = true;
      this.setCredentials(credentials);
      try {
        const { data } = await ServiceAuth.user.login(credentials);
        if (data) {
          return this.setTokens(data);
        }
      } catch (e) {
        console.error(e);
        this.errorSubmit(e);
      }
    },

    async checkUserForChange(credentials) {
      try {
        const response = await ServiceAuth.user.login(credentials, { confirmingPassword: true });
        if (response.status === 200) {
          this.validationUserErrors = '';

          this.userValidated = true;
        }
      } catch (e) {
        if (e.status === 302) {
          this.validationUserErrors = '';
          this.requireToken = true;
        }
        if (e.status === 400) {
          this.validationUserErrors = e.response.data.errors[1];
        }
      }
    },
    async loginTwofa(payload) {
      this.removeToken();
      this.loading = true;

      try {
        const { data } = await ServiceAuth.user.loginTwofa(payload);

        if (data) {
          return this.setTokens(data);
        }
      } catch (e) {
        this.errorSubmit(e);
      }
    },
    async fetchPendency() {
      try {
        const { data } = await ServiceCertificate.certidoes.getPendency();
        this.pendency = data;
      } catch (e) {
        this.pendency = false;
      }
    },
    async fetchNewTotalizers() {
      try {
        const { data } = await ServiceFinancial.financial.hasNewTotalizers();
        this.totalizers = data;
      } catch (e) {
        this.totalizers = false;
      }
    },
    removeToken() {
      localStorage.removeItem('access_Token');
      localStorage.removeItem('refresh_Token');
    },
    cleanError(payload) {
      this.error = payload;
    },
    errorSubmit(e) {
      if (e.message.includes(503) || e.message.includes(500)) {
        this.error = 'Serviço indisponível, tente em um momento';
        this.setMessage('');
      } else if (e.message.includes(401) || e.message.includes(400)) {
        this.error = e.response.data.errors?.[1] || 'Usuário ou senha inválidos';
        this.setMessage('');
      } else if (e.message.includes(404)) {
        this.error = e.response.data.errors?.[1] || 'Erro não mapeado';
        this.setMessage('');
      } else if (e.message.includes(302)) {
        this.error = e.response.data.errors?.[1] || 'Redirecionando...';
        this.setMessage('');
      } else {
        this.error = e.message;
        this.setMessage('');
      }

      this.token = null;
      this.refreshToken = null;
      this.user = null;
    },
    async setTokens(payload) {
      this.error = null;
      this.token = payload.access_token;
      this.refreshToken = payload.refresh_token;

      localStorage.setItem('access_Token', this.token);
      localStorage.setItem('refresh_Token', this.refreshToken);

      return this.getUser();
    },
    async setCredentials(payload) {
      this.credentials = payload;
    },
    setTwoFactorAuthentication(payload) {
      this.user.twoFactorAuthenticationEnabled = payload;
    },
    async fetchUserEstablishments() {
      if (!this.user?.id) return;

      try {
        this.loadingUserEstablishments = true;
        const { data } = await ServiceAdmin.userEstablishment.get(this.user.id);
        let userEstablishments = data.map((item) => item.establishment);
        userEstablishments = sortBy(userEstablishments, ['socialName', 'stateCode', 'city']);

        this.userEstablishments = userEstablishments;
      } catch (e) {
        console.error('Error fetching user establishments:', e);
      } finally {
        this.loadingUserEstablishments = false;
      }
    },
    async wasLast3Passwords1(data) {
      try {
        const response = await ServiceAuth.passwordReset.wasLast3Passwords(data);
        this.validPassword = response.data;
        return response.data;
      } catch (e) {
        console.error(e);
      }
    },
  },
});

function parseListWithoutGroups(establishments) {
  return establishments.map((item) => {
    return {
      id: item.details.cnpj,
      title: item.socialName,
      value: item.details.cnpj,
      isGroup: false,
    };
  });
}

function getEstablishmentsGroupedBySocialName(establishments) {
  return groupBy(establishments, 'socialName');
}

function parseListGroupItems(establishmentsGroupedBySocialName) {
  return Object.entries(establishmentsGroupedBySocialName).map(([socialName, establishments]) =>
    parseListGroupItem(socialName, establishments),
  );
}

function parseListGroupItem(groupItemName, items) {
  const groupId = uniqueId('list_group_item_');

  return {
    id: groupId,
    isGroup: true,
    title: groupItemName,
    items: items.map((establishment) => parseListItem(groupId, establishment)),
  };
}
function parseListItem(groupId, establishment) {
  return {
    id: establishment.id,
    groupId,
    title: establishment.details.socialName,
    subtitle: UtilsMask.cnpj(establishment.details.cnpj),
    value: establishment.details.cnpj,
    details: establishment.details,
  };
}

function separateAndFlattenGroupItems(groupItems) {
  const flattenedItems = [];

  groupItems.forEach(({ items, ...rest }) => {
    flattenedItems.push(rest);
    flattenedItems.push(...items);
  });

  return flattenedItems;
}
