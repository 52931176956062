import i18n from '@/plugins/vue-i18n';
import router from '@/router';
import { cloneDeep } from 'lodash';
import { defineStore } from 'pinia';

export const useStoreNotify = defineStore('notify', {
  state: () => ({
    notifications: [],
  }),

  actions: {
    notify({ id, message, details, type, closable = true, timeout = 5000, loading, linkText, onClickLink }) {
      const notification = {
        id: id ?? 'ag-notification-' + Date.now(),
        message,
        details,
        type,
        closable,
        timeout,
        loading,
        linkText,
        onClickLink,
      };

      this.notifications = [...cloneDeep(this.notifications), notification];
    },

    notifyInfo({ id, message, details, closable, timeout, loading, linkText, onClickLink }) {
      this.notify({ id, message, details, type: 'info', closable, timeout, loading, linkText, onClickLink });
    },

    notifySuccess({ id, message, details, closable, timeout, loading, linkText, onClickLink }) {
      this.notify({ id, message, details, type: 'success', closable, timeout, loading, linkText, onClickLink });
    },

    notifyWarning({ id, message, details, closable, timeout, loading, linkText, onClickLink }) {
      this.notify({ id, message, details, type: 'warning', closable, timeout, loading, linkText, onClickLink });
    },

    notifyError({ id, message, details, closable, timeout, loading, linkText, onClickLink }) {
      this.notify({ id, message, details, type: 'error', closable, timeout, loading, linkText, onClickLink });
    },

    notifyExportSuccess() {
      this.notifySuccess({
        message: i18n.global.t('toast.exportSuccess'),
        linkText: 'Clique aqui para acessar',
        closable: true,
        timeout: 5000,
        onClickLink: () => router.push({ name: 'RouteDocumentsDownload' }),
      });
    },

    notifyExportError() {
      this.notifyError({
        message: i18n.global.t('toast.exportError'),
        closable: true,
        timeout: 5000,
      });
    },

    notifyDownloadSuccess() {
      this.notifySuccess({
        message: i18n.global.t('toast.downloadSuccess'),
        closable: true,
        timeout: 5000,
      });
    },

    notifyDownloadError() {
      this.notifyError({
        message: i18n.global.t('toast.downloadError'),
        closable: true,
        timeout: 5000,
      });
    },

    removeNotification(notificationId) {
      this.notifications = cloneDeep(this.notifications.filter((notification) => notification.id !== notificationId));
    },
  },
});
