export const ptBR = {
  commons: {
    search: {
      empty: 'Não há registros de {name} a serem exibidos.',
      filter: {
        empty: 'Nenhum registro foi encontrado com os critérios de busca.',
      },
    },
    contactUs: 'Por favor, entre em contato através do chat para falar com nossa equipe.',
  },

  toast: {
    downloadSuccess: 'Download iniciado. Verifique o arquivo em seu computador.',
    downloadError:
      "{'Não foi possível iniciar o download. Por favor, tente novamente mais tarde. Se o problema persistir, entre em contato com o suporte@agnes.com.br.'}",
    exportSuccess:
      'Sua solicitação de exportação foi recebida com sucesso! Faça o download assim que o arquivo estiver disponível no menu "Exportações" (<span class="material-symbols-outlined" style="font-size:16px">download_2</span>).',
    exportError:
      '<strong>Não foi possível processar sua solicitação de exportação.</strong> Por favor, tente novamente mais tarde. Se o problema persistir, entre em contato com o suporte@agnes.com.br.',
  },

  company: {
    hintBotDays:
      'A configuração padrão para este campo é de 5 dias antes da data de vencimento da certidão vigente. Se precisar personalizar, especifique o número de dias para a coleta.',
    hintBotRetroactiveMonth:
      'A configuração padrão para este campo é de 60 meses. Se precisar personalizar, especifique o número de meses para a coleta retroativa.',
    hintBotRetroactiveDay:
      'A configuração padrão para este campo é de 18 dias. Se precisar personalizar, especifique o número de dias para a coleta retroativa.',
  },

  config: {
    noEmployerRegistered: 'Sua conta ainda não possui empregador(es) atribuído(s).',
  },

  eSocial: {
    employers: {
      title: 'Empregadores',
      subtitle: 'Visualize dados dos empregadores registrados no eSocial',
      fieldNotFilled: 'Campo sem preenchimento no xml',
      missingUnknowEvent: 'Ausência de evento de admissão, desligamento ou afastamento.',
    },
  },

  folhaDePagamento: {
    pisPasepHelper:
      'Código de incidência relacionado ao recolhimento de encargos destinados ao Programa de Integração Social (PIS) ou ao Programa de Formação do Patrimônio do Servidor Público (PASEP)',
  },

  certificate: {
    status: {
      '-1': {
        name: 'N/A',
        orientation:
          "{'A coleta de certidões para este CNPJ esta desabilitada. Caso tenha dúvidas ou deseje habilitar contate nosso time em suporte@agnes.com.br.'}",
      },

      0: {
        name: 'N/A',
        orientation:
          "{'Não foi possível emitir a certidão. Realizaremos novas tentativas de coleta. Em caso de dúvidas ou se desejar orientações adicionais contate suporte@agnes.com.br.'}",
      },

      1: {
        name: 'N/A',
        orientation: 'Certidão emitida com sucesso, você pode baixá-la no ícone de download no menu de ações.',
      },

      2: {
        name: 'N/A',
        orientation: 'Certidão emitida com sucesso, você pode baixá-la no ícone de download no menu de ações.',
      },

      3: {
        name: 'N/A',
        orientation:
          "{'Não foi possível emitir a certidão, consulte o relatório de situação fiscal para visualizar as pendências sinalizadas pela RFB. Em caso de dúvidas ou se desejar orientações adicionais contate suporte@agnes.com.br.'}",
      },

      4: {
        name: 'N/A',
        orientation:
          'Não foi possível emitir a certidão, foi sinalizado a existência de situação cadastral impeditiva. Entre em contato com nossos especialistas mediante suporte@agnes.com.br.',
      },

      5: {
        name: 'Análise inconsistente',
        orientation:
          "{'Não foi possível emitir a certidão, foi sinalizado que durante a tentativa de emissão estava ocorrendo alguma atualização de base de dados da RFB. Realizaremos novas tentativas de coleta assim que o ambiente estiver normalizado. Em caso de dúvidas ou se desejar orientações adicionais contate suporte@agnes.com.br.'}",
      },

      6: {
        name: 'Alguma base de apoio à verificação do contribuinte está indisponível',
        orientation:
          "{'Não foi possível emitir a certidão, foi sinalizado a indisponibilidade de um dos sistemas necessários para a emissão de certidão durante o processamento da requisição. Realizaremos novas tentativas de coleta assim que o ambiente estiver normalizado. Em caso de dúvidas ou se desejar orientações adicionais contate suporte@agnes.com.br.'}",
      },

      7: {
        name: 'Chave de consulta em processamento',
        orientation:
          "{'Não foi possível emitir a certidão, foi sinalizado instabilidade sistêmica durante o processamento do pedido de emissão da certidão. Realizaremos novas tentativas de coleta assim que o ambiente estiver normalizado. Em caso de dúvidas ou se desejar orientações adicionais contate suporte@agnes.com.br.'}",
      },
    },
  },
};
